import { Route } from '@angular/router';
import { AuthGuard } from '@vip/state/auth';
import { ValidaPinClienteGuard } from '@vip/state/cliente';
import { CadastroIncompletoGuard } from '@vip/state/cliente';
import { FilialSemEcommerceGuard } from '@vip/state/filial';

export const AplicativoMinhaContaRoutes: Route[] = [
  {
    path: 'cashback',
    canActivate: [AuthGuard],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/extrato-cashback'))
        .ExtratoCashbackContainerModule,
  },
  {
    path: 'favoritos',
    canActivate: [AuthGuard, FilialSemEcommerceGuard],
    data: {
      bottomNavigationIsVisible: true,
      breadcrumb: 'Favoritos',
    },
    loadChildren: async () =>
      (await import('@vip/container/favoritos')).FavoritosContainerModule,
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/notificacoes')).NotificacoesContainerModule,
  },
  {
    path: 'descontos-de-indicacao',
    redirectTo: 'convide-amigos',
  },
  {
    path: 'convide-amigos',
    canActivate: [AuthGuard],
    data: { bottomNavigationIsVisible: true },
    loadChildren: async () =>
      (await import('@vip/container/convide-amigos'))
        .ConvideAmigosContainerModule,
  },
  {
    path: 'minhas-listas',
    canActivate: [
      AuthGuard,
      CadastroIncompletoGuard,
      ValidaPinClienteGuard,
      FilialSemEcommerceGuard,
    ],
    children: [
      {
        path: '',
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/minhas-listas'))
            .MinhasListasContainerModule,
      },
      {
        path: 'detalhes',
        data: { bottomNavigationIsVisible: false },
        loadChildren: async () =>
          (await import('@vip/container/minhas-listas-detalhes'))
            .MinhasListasDetalhesContainerModule,
      },
      {
        path: 'detalhes/:id',
        data: { bottomNavigationIsVisible: false },
        loadChildren: async () =>
          (await import('@vip/container/minhas-listas-detalhes'))
            .MinhasListasDetalhesContainerModule,
      },
    ],
  },
  {
    path: 'minha-conta',
    canActivate: [AuthGuard, CadastroIncompletoGuard, ValidaPinClienteGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/perfil')).PerfilContainerModule,
      },
      {
        path: 'enderecos',
        redirectTo: '',
      },
      {
        path: 'endereco',
        loadChildren: async () =>
          (await import('@vip/container/endereco')).EnderecoContainerModule,
      },
      {
        path: 'cupons-de-credito',
        canActivate: [FilialSemEcommerceGuard],
        data: { bottomNavigationIsVisible: true },
        loadChildren: async () =>
          (await import('@vip/container/cupons')).CuponsContainerModule,
      },
      {
        path: 'dados-cadastrais',
        loadChildren: async () =>
          (await import('@vip/container/meus-dados')).MeusDadosContainerModule,
      },
      {
        path: 'dados-comerciais',
        loadChildren: async () =>
          (await import('@vip/container/meus-dados')).MeusDadosContainerModule,
      },
      {
        path: 'alterar-senha',
        loadChildren: async () =>
          (await import('@vip/container/alterar-senha'))
            .AlterarSenhaContainerModule,
      },
      {
        data: { bottomNavigationIsVisible: true },
        path: 'historico-de-compras',
        canActivate: [FilialSemEcommerceGuard],
        loadChildren: async () =>
          (await import('@vip/container/compras')).ComprasContainerModule,
      },
      {
        path: 'pedidos/solicitar-troca-ou-devolucao/:id',
        canActivate: [FilialSemEcommerceGuard],
        loadChildren: async () =>
          (await import('@vip/container/troca-devolucao'))
            .TrocaDevolucaoContainerModule,
      },
      {
        path: 'pedidos/detalhe/:compra',
        redirectTo: 'detalhes-compra/:compra',
      },
      {
        data: { bottomNavigationIsVisible: true },
        path: 'detalhes-compra/:compra',
        canActivate: [FilialSemEcommerceGuard],
        loadChildren: async () =>
          (await import('@vip/container/compra')).CompraContainerModule,
      },
      {
        path: 'excluir-conta',
        loadChildren: async () =>
          (await import('@vip/container/excluir-conta'))
            .ExcluirContaContainerModule,
      },
      {
        path: 'completar-cadastro',
        loadChildren: async () =>
          (await import('@vip/container/meus-dados')).MeusDadosContainerModule,
      },
    ],
  },
];
