import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  NgZone,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  IColunaPaginas,
  IMAGES_PATH,
  IPaginaInstitucional,
  ISocialLinks,
  LayoutUtilsService,
  IRelacionamentoCliente,
} from '@vip/core';
import { ListaInstitucionalBaseDirective } from '@vip/container/institucional';
import { Router } from '@angular/router';
import { IframeService } from '@vip/native/iframe';
import { LinkTypeService } from '@vip/core';

@Component({
  selector: 'vip-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FooterComponent extends ListaInstitucionalBaseDirective {
  @Input()
  textoFooter?: string;
  @Input()
  appleId?: string;
  @Input()
  package?: string;
  @Input()
  participaSupermercadoOnline?: string;
  @Input()
  link_ebit?: string;
  @Input()
  url_google?: string;
  @Input()
  collapsing = false;

  @Input()
  set relacionamentoCliente(value: null | IRelacionamentoCliente) {
    this._relacionamentoCliente = value;
    if (value) {
      const { telefone, whatsapp, email } = value;
      this.relacionamentoClienteViewModel = { telefone, whatsapp, email };
    }
  }

  get relacionamentoCliente(): null | IRelacionamentoCliente {
    return this._relacionamentoCliente;
  }

  colunasPaginas: IColunaPaginas[] = [];
  _redesSociais?: ISocialLinks;
  hasRedesSociais = false;
  relacionamentoClienteViewModel?: Pick<
    IRelacionamentoCliente,
    'email' | 'telefone' | 'whatsapp'
  >;

  private _paginaInstitucionais: IPaginaInstitucional[] = [];
  private _relacionamentoCliente: IRelacionamentoCliente | null = null;
  readonly ICONE_CONTATO: Record<
    string,
    'icon-phone' | 'icon-whatsapp' | 'icon-mail'
  > = {
    whatsapp: 'icon-whatsapp',
    email: 'icon-mail',
    telefone: 'icon-phone',
  };

  linkTypeMap: Record<string, 'internal' | 'external'> = {};

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(IMAGES_PATH) public imagesPath: string,
    private layoutUtilsService: LayoutUtilsService,
    protected override route: Router,
    protected override iframeService: IframeService,
    protected override ngZone: NgZone,
    private linkTypeService: LinkTypeService,
  ) {
    super(route, iframeService, ngZone);
  }

  @Input()
  set redesSociais(redesSociais: ISocialLinks | undefined) {
    this.hasRedesSociais = this.checkRedesSociais(redesSociais);
    this._redesSociais = redesSociais;
  }

  get redesSociais(): ISocialLinks | undefined {
    return this._redesSociais;
  }

  @Input()
  set paginaInstitucionais(paginaInstitucionais: IPaginaInstitucional[]) {
    this._paginaInstitucionais = paginaInstitucionais;
    this.setColunasPaginas(paginaInstitucionais);
  }

  get paginaInstitucionais(): IPaginaInstitucional[] {
    return this._paginaInstitucionais;
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  get isMobile(): boolean {
    return this.layoutUtilsService.isMobile();
  }

  get isRelacionamentoEmpty(): boolean {
    if (!this.relacionamentoClienteViewModel) {
      return true;
    }
    const { email, telefone, whatsapp } = this.relacionamentoClienteViewModel;
    return email === '' && telefone === '' && whatsapp === '';
  }

  get urlAppGooglePlay(): string {
    return `https://play.google.com/store/apps/details?id=${this.package}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`;
  }

  getPaginaUrl(pagina: IPaginaInstitucional, isDesktop?: boolean): string {
    return this.redirectToPage(pagina, isDesktop) || '';
  }

  setLinkTypes(): void {
    this._paginaInstitucionais.forEach((pagina) => {
      const url = this.getPaginaUrl(pagina, this.isDesktop);
      this.linkTypeMap[pagina.slug] = this.linkTypeService.verificarLink(url)
        .isInternal
        ? 'internal'
        : 'external';
    });
  }

  shouldShowAccordion(isDesktop: boolean): boolean {
    return !isDesktop;
  }

  setColunasPaginas(paginaInstitucionais: IPaginaInstitucional[]) {
    this.colunasPaginas = [];
    this.colunasPaginas.push({
      titulo: 'Quem somos',
      paginas: paginaInstitucionais.filter((pagina) =>
        pagina.slug.match(/quem-somos(-\d+)?/i),
      ),
    });

    this.colunasPaginas.push({
      titulo: 'Forma de Pagamento',
      paginas: paginaInstitucionais.filter((pagina) =>
        pagina.slug.match(/formas-de-pagamento(-\d+)?/i),
      ),
    });

    this.colunasPaginas.push({
      titulo: 'Institucional',
      paginas: [...paginaInstitucionais],
    });
  }

  checkRedesSociais(redesSociais: ISocialLinks | undefined) {
    if (!redesSociais) return false;
    return !Object.values(redesSociais).every((x) => x === null || x === '');
  }

  handleClickContato(key: string): string {
    if (!this.relacionamentoCliente) return '';

    let url = '';
    const { email, whatsapp, mensagem_whatsapp, telefone } =
      this.relacionamentoCliente;

    switch (key) {
      case 'email':
        url = `mailto:${email}`;
        break;
      case 'telefone':
        url = `tel:${telefone.replace(/[^0-9]/g, '')}`;
        break;
      case 'whatsapp':
        url = `https://wa.me/55${whatsapp.replace(/[^0-9]/g, '')}?text=${mensagem_whatsapp.replace(/ /g, '%20')}`;
        break;
    }

    if (url && (this.isMobile || key === 'whatsapp')) {
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        safeUrl,
      ) as string;
    }

    return url;
  }

  isExternalLink(url: string | null | undefined): boolean {
    if (!url) return false;
    return url.startsWith('http://') || url.startsWith('https://');
  }

  getFilteredContato(
    contato: Record<string, string> | undefined | null,
  ): Record<string, string> {
    if (!contato) return {};

    return Object.keys(contato).reduce(
      (acc, key) => {
        if (contato[key].trim() !== '') {
          acc[key] = contato[key];
        }
        return acc;
      },
      {} as Record<string, string>,
    );
  }
}
